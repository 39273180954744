<template>
    <v-container>
        <PageHeader :title="title" :items="items" />
        
        <loading-flux :value="loading"></loading-flux>
        
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-alert
                            :show="segundosMensajeActualizacion"
                            dismissible
                            :variant="mensaje.variant"
                            @dismissed="segundosMensajeActualizacion = 0"
                            @dismiss-count-down="
                                actualizarSegundosMensajeActualizacion
                            "
                        >
                            {{ mensaje.texto }}
                        </b-alert>
                        <!-- <div class="d-flex">
                            <div class="card-title col-auto me-auto">
                                Bodega {{this.usuarioBodega.codigo_bodega}}
                            </div>
                            <Menu :lugarInstalacion="lugarInstalacion" />
                        </div> -->

                        <div class="row ">
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <!-- <label for="bodega">Bodega Movil</label> -->
                                    <multiselect
                                        v-model="bodegaMovil"
                                        :options="bodegas"
                                        placeholder="Seleccionar Bodega"
                                        value="codigo_bodega"
                                        label="codigo_bodega"
                                        :searchable="true"
                                        :loading="bodegasCargando"
                                        open-direction="bottom"
                                        class="helo"
                                        @select="obtenerStockProductosLocal"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="col-lg-9 text-md-end">
                                <button
                                    class="btn btn-primary w-lg"
                                    @click="exportarExcel()"
                                >
                                    <i class="bx bx-download me-1"></i>
                                    Exportar
                                </button>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Mostrar&nbsp;
                                        <b-form-select
                                            class="form-select form-select-sm"
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Buscar:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Buscar..."
                                            class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                id="tabla-inventario"
                                v-if="renderComponent"
                                class="datatables tabla-personalizada"
                                :items="productosFiltrados"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                select-mode="single"
                                selectable
                                @filtered="onFiltered"
                                :busy="productosCargando"
                            >
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner
                                            class="align-middle"
                                        ></b-spinner>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul
                                        class="pagination pagination-rounded mb-0"
                                    >
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>
<script>
//import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { productoMethods, productoComputed } from "@/state/helpers";
import { read, utils, writeFileXLSX } from "xlsx";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
// import Menu from "@/components/widgets/menu/menu";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import { bodegaMethods, bodegaComputed } from "@/state/helpers";
import { usuarioBodegaMethods, usuarioBodegaComputed } from "@/state/helpers";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    page: {
        title: "Inventario",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        //Layout,
        PageHeader,
        Multiselect,
    },

    data() {
        return {
            bodegas: [],
            bodegasCargando: false,
            bloquearControles: false,
            renderProductos: true,
            renderComponent: true,
            loading: false,
            loadingSolicitud: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            lugarInstalacion: {},
            proyectos: [],
            title: "Inventario",
            items: [
                {
                    text: "Bodega Movil",
                    active: true,
                },
                {
                    text: "Inventario",
                    href: `/bodegamovil/inventario`,
                },
            ],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "Producto",
            sortDesc: false,
            fields: [
                {
                    key: "Producto",
                    label: "Codigo",
                    sortable: false,
                },
                {
                    key: "Descripción",
                    label: "Nombre Producto",
                    sortable: true,
                },
                {
                    key: "Stock",
                    label: "Stock",
                    sortable: true,
                },
                // {
                //     key: "Bodega",
                //     label:'Bodega',
                //     sortable: true,
                // },
            ],
            codigoProyecto: null,
            codigoLugarInstalacion: null,
            productos: [],
            productosCargando: false,
            rolModuloPermiso: {},
            productosFiltrados: [],
            usuarioBodega: {},
            bodegaMovil: null,
        };
    },

    mounted() {
        this.obtenerBodegasLocal();

        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...productoMethods,
        ...proyectoMethods,
        ...usuarioBodegaMethods,
        ...bodegaMethods,
        exportarExcel() {
            this.productosFiltrados.map((producto) => {
                delete producto.Proyecto;
            });
            let data = utils.json_to_sheet(this.productosFiltrados);
            const workbook = utils.book_new();
            const filename = "Inventario";
            utils.book_append_sheet(workbook, data, filename);
            writeFileXLSX(workbook, `${filename}.xlsx`);
        },
        descripcionProducto(producto) {
            return `${producto.Producto} - (${producto.Stock}) - ${producto.Descripción}`;
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },

        obtenerBodegasLocal() {
            this.bodegasCargando = true;
            this.obtenerBodegas()
                .then((res) => {
                    this.bodegas.unshift({
                        codigo_bodega: "TODO",
                        nombre_bodega: "TODO",
                    });
                    res.body.map((item) => {
                        this.bodegas.push(item);
                    })
                    this.bodegasCargando = false;
                })
                .catch((error) => {
                    this.bodegasCargando = false;
                });
        },
        obtenerStockProductosLocal(item) {
            this.productosCargando = true;
            this.obtenerStockProductos()
                .then((res) => {
                    this.productos = res.body;

                    if (item == null || item.codigo_bodega == "TODO") {
                        this.productosFiltrados = this.productos.filter(
                            (c) => c.Proyecto == "Bodega"
                        );
                    } else {
                        this.productosFiltrados = this.productos.filter(
                            (c) =>
                                c.Bodega.toLowerCase() ==
                                item.codigo_bodega.toLowerCase()
                        );
                    }
                    this.totalRows = this.productosFiltrados.length
                    this.mostrarProductos = false;
                    this.$nextTick(() => {
                        this.mostrarProductos = true;
                    });

                    this.productosCargando = false;
                })
                .catch((error) => {
                    this.productosCargando = false;
                });
        },

        filtrarStockProductosLocal(item) {
            this.productosCargando = true;
            this.obtenerStockProductos()
                .then((res) => {
                    this.productos = res.body;

                    if (item == null || item.codigo_bodega == "TODO") {
                        this.productosFiltrados = this.productos.filter(
                            (c) => c.Proyecto == "Bodega"
                        );
                    } else {
                        this.productosFiltrados = this.productos.filter(
                            (c) =>
                                c.Bodega.toLowerCase() ==
                                item.codigo_bodega.toLowerCase()
                        );
                    }

                    this.mostrarProductos = false;
                    this.$nextTick(() => {
                        this.mostrarProductos = true;
                    });

                    this.productosCargando = false;
                })
                .catch((error) => {
                    this.productosCargando = false;
                });
        },
        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },

        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>

<style>
.crearActividadesProyecto {
    margin-right: 10px;
}

.table-solicitud-material-detalle {
    height: 300px;
}
</style>
